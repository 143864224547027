import {
  Box,
  Button,
  Icon,
  visuallyHidden,
  usePreventScroll,
  Txt,
  usePortal,
} from '@florencecard/components';
import { css } from '@emotion/react';
import { colors } from '@florencecard-shared/colors';
import { UserType } from '@florencecard-shared/user';
import NextLink from 'next/link';
import Router from 'next/router';
import React, { useCallback, useEffect, useRef } from 'react';
import FooterNew from '~/components/FooterNew';
import IntroHeader from '~/components/IntroHeader';
import { useAuth } from '~/hooks/auth';

let uniqueId = 0;

interface Link {
  name: string;
  path: string;
  color?: boolean;
}

const normalUserLinks: Link[] = [
  {
    name: '모바일청첩장',
    path: '/store-list',
  },
  {
    name: '청첩장 만들기',
    path: '/create',
    color: true,
  },
];

const enterpriseUserLinks: Link[] = [
  {
    name: '대시보드',
    path: '/my',
  },
];

const couponLinks: Link[] = [
  {
    name: '쿠폰 구매하기',
    path: '/coupons',
  },
  {
    name: '쿠폰 결제내역',
    path: '/my/coupons/purchases',
  },
  {
    name: '쿠폰 이용내역',
    path: '/my/coupons/usage',
  },
];

const myLinks: Link[] = [
  {
    name: '구매내역',
    path: '/my/purchases',
  },
  {
    name: '취소내역',
    path: '/my/refunds',
  },
  {
    name: '참석의사 통계',
    path: '/my/attends',
  },
  {
    name: '방명록 관리',
    path: '/my/guest-books',
  },
  {
    name: '회원정보수정',
    path: '/my/profile',
  },
];

const helpingLinks: Link[] = [
  // {
  //   name: '공지사항',
  //   path: '/notice',
  // },
  {
    name: '1:1 문의하기',
    path: '/inquiry',
  },
];

interface Props {
  open: boolean;
  onClose?(): void;
}

export default function IntroMobileMenu({ open, onClose }: Props) {
  const [user] = useAuth({
    redirectIfUnauthorized: false,
  });
  const isEnterpriseUser = user?.type === UserType.엔터프라이즈;

  const createPortal = usePortal();
  const titleId = useRef(`intro-mobile-menu-title-${uniqueId++}`);

  const hrefToPath = useCallback((link: Link) => {
    Router.push(link.path);
  }, []);

  usePreventScroll(open);

  useEffect(() => {
    if (open) {
      const handler = () => {
        onClose?.();
      };

      Router.events.on('routeChangeComplete', handler);

      return () => {
        Router.events.off('routeChangeComplete', handler);
      };
    }

    return () => {};
  }, [open, onClose]);

  return createPortal(
    open ? (
      <Box
        role="dialog"
        position="fixed"
        display="flex"
        flexDirection="column"
        left={0}
        right={0}
        top="0"
        height="100%"
        backgroundColor={colors.grayBackground}
        zIndex={20}
        overflow="hidden"
        overflowY="auto"
        css={css`
          -webkit-overflow-scrolling: touch;
        `}
        aria-modal={true}
        aria-labelledby={titleId.current}
      >
        <Box
          as="header"
          backgroundColor={colors.white}
          borderBottom={[`1px solid ${colors.grayBackground}`, 'none']}
          height={IntroHeader.sizes.mobile}
        >
          <Box
            display="flex"
            alignItems="center"
            margin="0 auto"
            paddingLeft={16}
            paddingRight={12}
            height="100%"
          >
            <NextLink href="/">
              <Box as="a" display="inline-flex" alignItems="center" height={48} cursor="pointer">
                <Box
                  as="img"
                  height={14}
                  src="https://assets.florencecard.me/images/logo-color.svg"
                  alt="플로렌스카드"
                />
              </Box>
            </NextLink>
            <Box flex={1} />
            <Box>
              <Button
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                size={40}
                width={40}
                paddingLeft={0}
                paddingRight={0}
                aria-label="메뉴 닫기"
                onClick={onClose}
              >
                <Icon name="cancel" size={24} color={colors.grayLight} />
              </Button>
            </Box>
          </Box>
        </Box>
        <h1 id={titleId.current} css={visuallyHidden}>
          메뉴
        </h1>
        {!isEnterpriseUser ? <LinkList links={normalUserLinks} onClick={hrefToPath} /> : null}
        {isEnterpriseUser ? <LinkList links={enterpriseUserLinks} onClick={hrefToPath} /> : null}
        {isEnterpriseUser ? (
          <>
            <Txt as="h2" padding="24px 16px 8px 16px" fontSize={14} color={colors.grayBold}>
              쿠폰
            </Txt>
            <LinkList links={couponLinks} onClick={hrefToPath} />
          </>
        ) : null}
        {!isEnterpriseUser ? (
          <>
            <Txt as="h2" padding="24px 16px 8px 16px" fontSize={14} color={colors.grayBold}>
              내 페이지
            </Txt>
            <LinkList links={myLinks} onClick={hrefToPath} />
          </>
        ) : null}
        <Txt as="h2" padding="24px 16px 8px 16px" fontSize={14} color={colors.grayBold}>
          고객센터
        </Txt>
        <LinkList links={helpingLinks} onClick={hrefToPath} />
        <Box flex={1} />
        <FooterNew />
      </Box>
    ) : null,
  );
}

const LinkList = ({ links, onClick }: { links: Link[]; onClick?(link: Link): void }) => (
  <Box as="ul" margin={0} padding={0} listStyle="none" backgroundColor={colors.white}>
    {links.map((link) => (
      <LinkItem key={link.name + link.path} link={link} onClick={() => onClick?.(link)} />
    ))}
  </Box>
);

const LinkItem = ({ link, onClick }: { link: Link; onClick?(): void }) => (
  <Box as="li" display="flex" alignItems="center" width="100%" height={48}>
    <Txt
      role="button"
      fontSize={16}
      fontWeight="bold"
      padding="8px 16px"
      cursor="pointer"
      color={link.color ? colors.primary : colors.grayBold}
      onClick={onClick}
    >
      {link.name}
    </Txt>
  </Box>
);
