/* eslint-disable @typescript-eslint/ban-types */
import {
  createDispatchable,
  Creator,
  DisallowTypeProperty,
  Dispatchable,
  DispatchableCreator,
  FunctionWithParametersType,
  TypedDispatchable,
} from '@florencecard-lib/dispatchable';
import { OperatorFunction } from 'rxjs';
import { filter } from 'rxjs/operators';
import { matchType } from './util';

export const ACTION_TYPE = '@action';

export interface Action extends Dispatchable {}

export interface TypedAction<T extends string> extends Action {
  readonly type: T;
}

export type ActionTypeOf<T> = T extends Creator ? ReturnType<T> : never;

export function isAction(value: unknown): value is Action {
  return matchType(value, ACTION_TYPE);
}

export type ActionCreator<T extends string = string, C extends Creator = Creator> = C &
  TypedAction<T>;

export function ofType<
  AC extends ActionCreator[],
  U extends Action = Action,
  V = ReturnType<AC[number]>
>(...allowedTypes: AC): OperatorFunction<U, V>;
export function ofType<
  E extends Extract<U, { type: T1 }>,
  AC extends ActionCreator,
  T1 extends string | AC,
  U extends Action = Action,
  V = T1 extends string ? E : ReturnType<Extract<T1, AC>>
>(t1: T1): OperatorFunction<U, V>;
export function ofType<V extends Action>(
  ...allowedTypes: Array<string | ActionCreator>
): OperatorFunction<Action, V>;
export function ofType(
  ...allowedTypes: Array<string | ActionCreator>
): OperatorFunction<Action, Action> {
  return filter((action: Action) =>
    allowedTypes.some((typeOrActionCreator) => {
      if (typeof typeOrActionCreator === 'string') {
        // Comparing the string to type
        return typeOrActionCreator === action.type;
      }

      // We are filtering by ActionCreator
      return typeOrActionCreator.type === action.type;
    }),
  );
}

export function createAction<T extends string>(
  type: T,
): DispatchableCreator<T, () => TypedAction<T>>;
export function createAction<T extends string, P extends object>(
  type: T,
  config: { _as: 'props'; _p: P },
): DispatchableCreator<T, (props: P) => P & TypedAction<T>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createAction<T extends string, P extends any[], R extends object>(
  type: T,
  creator: Creator<P, DisallowTypeProperty<R>>,
): FunctionWithParametersType<P, R & TypedAction<T>> & TypedAction<T>;
export function createAction<T extends string, C extends Creator>(
  type: T,
  config?: C,
): DispatchableCreator<T, (props: object) => object & TypedDispatchable<T>> {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return createDispatchable(ACTION_TYPE, type, config!);
}
