import { Box, Button, Icon, Stack } from '@florencecard/components';
import { css } from '@emotion/react';
import { colors } from '@florencecard-shared/colors';
import { UserType } from '@florencecard-shared/user';
import Link from 'next/link';
import Router from 'next/router';
import React, { ComponentProps, useCallback, useState } from 'react';
import IntroMenuList from '~/components/IntroMenuList';
import IntroMobileMenu from '~/components/IntroMobileMenu';
import KakaopayBanner from '~/components/KakaopayBanner';
import { useClientSideRendering, usePersistentFlagWithExpiry } from '~/hooks';
import { useAuth } from '~/hooks/auth';

const KAKAOPAY_BANNER_EXPOSE_PERSISTENT_FLAG_KEY = '@@florence/kakaopay-banner-expose';
const ONE_DAY = 24 * 60 * 60 * 1000;

const DESKTOP_SIZE = 70;
const MOBILE_SIZE = 56;

interface Props {
  disableBanner?: boolean;
}

const hrefToLoginPage = () => {
  Router.push('/login');
};

const hrefToCreateLetterPage = () => {
  Router.push('/create');
};

const hrefToCouponPurchasePage = () => {
  Router.push('/coupons');
};

function IntroHeader({ disableBanner = false }: Props) {
  const [user, { logout }] = useAuth({
    redirectIfUnauthorized: false,
  });
  const isEnterpriseUser = user?.type === UserType.엔터프라이즈;

  const userReady = user !== undefined;
  const userLoggedIn = user !== null;

  const mounted = useClientSideRendering();
  const [showKakaopayBanner, hideKakaopayBannerWithExpiry] = usePersistentFlagWithExpiry(
    KAKAOPAY_BANNER_EXPOSE_PERSISTENT_FLAG_KEY,
  );
  const hideKakaopayBanner = useCallback(() => {
    hideKakaopayBannerWithExpiry(ONE_DAY);
  }, [hideKakaopayBannerWithExpiry]);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const shouldShowKakaopayBanner =
    !disableBanner && !isMobileMenuOpen && mounted && showKakaopayBanner;

  return (
    <>
      {shouldShowKakaopayBanner ? <KakaopayBanner onClose={hideKakaopayBanner} /> : null}
      <Box
        as="header"
        position="sticky"
        left={0}
        top={0}
        right={0}
        backgroundColor={colors.white}
        zIndex={19}
        height={[MOBILE_SIZE, DESKTOP_SIZE]}
        borderBottom={[`1px solid ${colors.grayBackground}`, 'none']}
      >
        <Box
          display="flex"
          alignItems="center"
          margin="0 auto"
          maxWidth={1140}
          paddingLeft={[16, 20]}
          paddingRight={[12, 20]}
          height="100%"
        >
          <Link href="/">
            <Box as="a" display="inline-flex" alignItems="center" height={48} cursor="pointer">
              <Box
                as="img"
                height={[14, 24]}
                src="https://assets.florencecard.me/images/logo-color.svg"
                alt="플로렌스카드"
              />
            </Box>
          </Link>
          {/* Desktop */}
          <Box display={['none', 'block']} marginLeft={40}>
            <IntroMenuList>
              {!isEnterpriseUser ? (
                <IntroMenuList.AnchorItem href="/store-list">
                  모바일 청첩장
                </IntroMenuList.AnchorItem>
              ) : null}
              <IntroMenuList.AnchorItem href="/inquiry">문의하기</IntroMenuList.AnchorItem>
            </IntroMenuList>
          </Box>

          <Box flex={1} />

          {/* Desktop */}
          <Box display={['none', 'flex']} alignItems="center">
            <IntroMenuList>
              {userReady ? (
                <>
                  <IntroMenuList.ButtonItem
                    color={colors.grayRegular}
                    onClick={userLoggedIn ? logout : hrefToLoginPage}
                  >
                    {userLoggedIn ? '로그아웃' : '로그인'}
                  </IntroMenuList.ButtonItem>
                  {userLoggedIn ? (
                    <IntroMenuList.AnchorItem href="/my" color={colors.grayRegular}>
                      내 페이지
                    </IntroMenuList.AnchorItem>
                  ) : null}
                </>
              ) : null}
            </IntroMenuList>
            {user?.type === UserType.엔터프라이즈 ? (
              <PrimaryButton onClick={hrefToCouponPurchasePage}>쿠폰 구매하기</PrimaryButton>
            ) : (
              <PrimaryButton onClick={hrefToCreateLetterPage}>청첩장 만들기</PrimaryButton>
            )}
          </Box>

          {/* Mobile */}
          <Box display={['block', 'none']}>
            {userReady ? (
              userLoggedIn ? (
                <Stack direction="horizontal" gutter={4}>
                  <Link href="/my">
                    <Button
                      display="inline-flex"
                      alignItems="center"
                      justifyContent="center"
                      size={40}
                      width={40}
                      paddingLeft={0}
                      paddingRight={0}
                      aria-label="내 페이지"
                    >
                      <Icon name="account" size={24} color={colors.grayLight} />
                    </Button>
                  </Link>
                  <Button
                    display="inline-flex"
                    alignItems="center"
                    justifyContent="center"
                    size={40}
                    width={40}
                    paddingLeft={0}
                    paddingRight={0}
                    aria-label="메뉴 열기"
                    onClick={() => {
                      setIsMobileMenuOpen((x) => !x);
                    }}
                  >
                    <Icon name="menu" size={24} color={colors.grayLight} />
                  </Button>
                </Stack>
              ) : (
                <IntroMenuList>
                  <IntroMenuList.AnchorItem href="/inquiry" color={colors.grayRegular}>
                    문의하기
                  </IntroMenuList.AnchorItem>
                  <PrimaryButton onClick={hrefToLoginPage}>로그인</PrimaryButton>
                </IntroMenuList>
              )
            ) : null}
          </Box>
        </Box>
      </Box>
      <IntroMobileMenu
        open={isMobileMenuOpen}
        onClose={() => {
          setIsMobileMenuOpen(false);
        }}
      />
    </>
  );
}

IntroHeader.sizes = {
  desktop: DESKTOP_SIZE,
  mobile: MOBILE_SIZE,
} as const;

export default IntroHeader;

const PrimaryButton = ({ children, ...props }: Omit<ComponentProps<typeof Button>, 'size'>) => (
  <Button
    size={[32, 40]}
    border={`1px solid ${colors.primary}`}
    borderRadius={[16, 24]}
    marginLeft={[6, 14]}
    paddingLeft={[24, 32]}
    paddingRight={[24, 32]}
    backgroundColor={colors.white}
    color={colors.primary}
    fontSize={[13, 14]}
    css={css`
      &:hover {
        background-color: ${colors.primary};
        color: ${colors.white};
      }
    `}
    {...props}
  >
    {children}
  </Button>
);
