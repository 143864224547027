import { Box, BoxProps, FlexProps, Flex, buttonReset } from '@florencecard/components';
import { css } from '@emotion/react';
import { colors } from '@florencecard-shared/colors';
import Link from 'next/link';
import React from 'react';

function IntroMenuList({ children, ...props }: FlexProps<'ul'>) {
  return (
    <Flex<'ul'>
      as="ul"
      alignItems="center"
      css={css`
        margin: 0;
        padding: 0;
      `}
      {...props}
    >
      {children}
    </Flex>
  );
}

function IntroMenuListAnchorItem({
  color = colors.primary,
  href,
  children,
  ...props
}: BoxProps<'li'> & {
  href: string;
}) {
  return (
    <Box as="li" display="inline-block" listStyle="none" color={color} {...props}>
      <Link href={href}>
        <a css={menuItemClickableCss}>{children}</a>
      </Link>
    </Box>
  );
}

function IntroMenuListButtonItem({
  color = colors.primary,
  children,
  onClick,
  ...props
}: Omit<BoxProps<'li'>, 'onClick'> & {
  onClick?: () => void;
}) {
  return (
    <Box as="li" display="inline-block" listStyle="none" color={color} {...props}>
      <button css={menuItemClickableCss} onClick={onClick}>
        {children}
      </button>
    </Box>
  );
}

IntroMenuList.AnchorItem = IntroMenuListAnchorItem;
IntroMenuList.ButtonItem = IntroMenuListButtonItem;

export default IntroMenuList;

const menuItemClickableCss = css`
  ${buttonReset};
  padding: 0 17.5px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  color: inherit;
  cursor: pointer;
  background: transparent;

  @media screen and (max-width: 700px) {
    font-size: 13px;
    padding: 0 9px;
  }
`;
