import { Icon, buttonReset, Spacing, Txt } from '@florencecard/components';
import styled from '@emotion/styled';
import { colors } from '@florencecard-shared/colors';
import React, { memo } from 'react';
import { useClientSideRendering } from '~/hooks';

const BREAKPOINT = 768;
const isBreakpoint = `@media screen and (max-width: ${BREAKPOINT}px)`;

interface Props {
  onClose?(): void;
}

function KakaopayBanner({ onClose }: Props) {
  const rendered = useClientSideRendering();

  if (!rendered) {
    return null;
  }

  return (
    <Banner role="banner">
      <BannerInner>
        <KakaopayLogo
          alt="카카오페이"
          src="https://assets.florencecard.me/images/ic-kakaopay@3x.png"
          width={94}
          height={30}
        />
        <div>
          <Txt
            as="h1"
            fontSize={[14, 17]}
            fontWeight="bold"
            lineHeight={['20px', '25px']}
            color={colors.grayBold}
          >
            카카오톡 간편송금을 지원합니다!
          </Txt>
          <Spacing size={[4, 2]} />
          <Txt as="p" fontSize={[12, 14]} lineHeight={['18px', '20px']} color={colors.grayBold}>
            이제 카카오톡에서 쉽게 축의금을 송금할 수 있어요.
          </Txt>
        </div>
        <CloseButton aria-label="오늘 하루 보지 않기" onClick={onClose}>
          <span>오늘 하루 보지 않기</span>
          <Icon name="close" size={18} color={colors.black} />
        </CloseButton>
      </BannerInner>
    </Banner>
  );
}

export default memo(KakaopayBanner);

const Banner = styled.section`
  padding: 16px;
  background-color: #ffdf00;

  ${isBreakpoint} {
    padding: 20px 16px;
  }
`;

const BannerInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1108px;
  margin: 0 auto;

  ${isBreakpoint} {
    justify-content: space-between;
  }
`;

const KakaopayLogo = styled.img`
  margin-right: 30px;
  width: 94px;
  height: 30px;

  ${isBreakpoint} {
    display: none;
  }
`;

const CloseButton = styled.button`
  ${buttonReset};
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;

  > span {
    margin-right: 4px;
  }

  ${isBreakpoint} {
    position: static;
    right: unset;
    top: unset;

    > span {
      display: none;
    }
  }
`;
