/* eslint-disable @typescript-eslint/no-explicit-any */
import { props } from '@florencecard-lib/dispatchable';
import type { LetterTheme } from '@florencecard-shared/letter';
import type {
  LetterAccount,
  LetterGroomInfo,
  LetterInfoType,
  LetterNoteInfo,
  LetterOGTitleVariant,
  LetterPhotoGalleryType,
  LetterPhotoInfo,
  LetterPlaceInfo,
  LetterPriestInfo,
  LetterWayToComeInfo,
} from '~/models';
import { createAction } from '~/store/seed-work';

export const updateLetterEmailAction = createAction(
  '청첩장.이메일_변경',
  props<{
    email: string;
  }>(),
);

export const updateLetterGroomInfoAction = createAction(
  '청첩장.신랑정보_변경',
  props<{
    key: keyof LetterGroomInfo;
    value: any;
  }>(),
);

export const updateLetterPriestInfoAction = createAction(
  '청첩장.신부정보_변경',
  props<{
    key: keyof LetterPriestInfo;
    value: any;
  }>(),
);

export const updateLetterPlaceInfoAction = createAction(
  '청첩장.식장정보_변경',
  props<{
    key: keyof LetterPlaceInfo;
    value: any;
  }>(),
);

export const updateLetterWayToComeInfoAction = createAction(
  '청첩장.오시는길_변경',
  props<{
    key: keyof LetterWayToComeInfo;
    value: any;
  }>(),
);

export const updateLetterNoteInfoAction = createAction(
  '청첩장.글귀_변경',
  props<{
    key: keyof LetterNoteInfo;
    value: string;
  }>(),
);

export const updateLetterPhotoInfoAction = createAction(
  '청첩장.사진_변경',
  props<{
    key: keyof LetterPhotoInfo;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  }>(),
);

export const appendLetterPhotoGalleryAction = createAction(
  '청첩장.사진_갤러리_추가',
  props<{
    imageUrl: string;
  }>(),
);

export const updateLetterPhotoGalleryAction = createAction(
  '청첩장.사진_갤러리_수정',
  props<{
    prevImageUrl: string;
    nextImageUrl: string;
  }>(),
);

export const updateLetterPhotoGalleryTypeAction = createAction(
  '청첩장.사진_갤러리_타입_수정',
  props<{
    galleryType: LetterPhotoGalleryType;
  }>(),
);

export const deleteLetterPhotoGalleryAction = createAction(
  '청첩장.사진_갤러리_제거',
  props<{
    imageUrl: string;
  }>(),
);

export const toggleLetterAccountsInfoAction = createAction(
  '청첩장.계좌표시여부_토글',
  props<{
    needed: boolean;
  }>(),
);

export const enableLetterAdditionalAccountsInfoAction = createAction(
  '청첩장.추가계좌번호_입력여부_활성화',
  props<{
    target: '신랑측' | '신부측';
  }>(),
);

export const updateLetterAccountsTitleAction = createAction(
  '청첩장.계좌_제목_변경',
  props<{
    value: string;
  }>(),
);

export const updateLetterAccountsDescriptionAction = createAction(
  '청첩장.계좌_보조문구_변경',
  props<{
    value: string;
  }>(),
);

export const updateLetterGroomAccountInfoAction = createAction(
  '청첩장.신랑계좌정보_변경',
  props<{
    key: keyof LetterAccount;
    value: string;
  }>(),
);

export const updateLetterAdditionalGroomAccountInfoAction = createAction(
  '청첩장.추가신랑계좌정보_변경',
  props<{
    key: keyof LetterAccount;
    value: string;
  }>(),
);

export const updateLetterPriestAccountInfoAction = createAction(
  '청첩장.신부계좌정보_변경',
  props<{
    key: keyof LetterAccount;
    value: string;
  }>(),
);

export const updateLetterAdditionalPriestAccountInfoAction = createAction(
  '청첩장.추가신부계좌정보_변경',
  props<{
    key: keyof LetterAccount;
    value: string;
  }>(),
);

export const updateLetterThemeAction = createAction(
  '청첩장.테마_변경',
  props<{
    theme: LetterTheme;
  }>(),
);

export const updateLetterOGInfoAction = createAction(
  '청첩장.공유하기정보_변경',
  props<{
    title: LetterOGTitleVariant;
  }>(),
);

export const saveLetterInfoAction = createAction(
  '청첩장.임시저장',
  props<{ target: LetterInfoType; showAlert?: boolean }>(),
);

export const naverstoreAction = createAction('청첩장.네이버스토어');

export const toggleLetterAttendsEnabledAction = createAction(
  '청첩장.참석의사_활성화_여부_토글',
  props<{
    enabled: boolean;
  }>(),
);

export const updateLetterAttendsTitleAction = createAction(
  '청첩장.참석의사_제목_변경',
  props<{
    value: string;
  }>(),
);

export const updateLetterAttendsDescriptionAction = createAction(
  '청첩장.참석의사_보조문구_변경',
  props<{
    value: string;
  }>(),
);

export const toggleLetterGuestBooksEnabledAction = createAction(
  '청첩장.방명록_활성화_여부_토글',
  props<{
    enabled: boolean;
  }>(),
);

export const updateLetterYoutubeLinkAction = createAction(
  '청첩장.식전영상_유튜브_링크_변경',
  props<{
    link: string;
  }>(),
);

export const updateLetterYoutubeLiveLinkAction = createAction(
  '청첩장.유튜브_라이브_링크_변경',
  props<{
    link: string;
  }>(),
);
