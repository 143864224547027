import { BusinessClass } from '@florencecard-shared/business';

export enum UserType {
  일반,
  엔터프라이즈,
}

export enum UserBusinessType {
  '개인(제휴)',
  기업,
}

export enum UserAuthType {
  로컬,
  카카오,
  네이버,
  페이스북,
}

export enum UserStatus {
  정상 = 'ok',
  엔터프라이즈_회원_가입요청중 = 'pending-enterprise-signup',
  탈퇴 = 'withdrawn',
}

export interface BaseUser {
  id: number;
  type: UserType;
  status: UserStatus;
  authTypes: UserAuthType[];
  email: string;
  name?: string;
  businessType?: UserBusinessType;
  /** 상호명. 엔터프라이즈 회원인 경우만 존재 */
  businessName?: string;
  /** 사업자등록번호. 엔터프라이즈 회원인 경우만 존재 */
  businessNumber?: string;
  /** 대표자명. 엔터프라이즈 회원인 경우만 존재 */
  businessOwnerName?: string;
  /** 업태. 엔터프라이즈 회원인 경우만 존재 */
  businessClass?: BusinessClass;
  /** 종목. 엔터프라이즈 회원인 경우만 존재 */
  businessSubClass?: string;
  /** 담당자명. 엔터프라이즈 회원인 경우만 존재 */
  businessManagerName?: string;
  /** 담당자 연락처. 엔터프라이즈 회원인 경우만 존재 */
  businessManagerPhone?: string;
  /** 사업자등록증 사진. 엔터프라이즈 회원인 경우만 존재 */
  businessRegistrationImageUrl?: string;
  createdAt: string;
  updatedAt: string;
}

export interface NormalUser extends BaseUser {
  type: UserType.일반;
}

export interface EnterpriseUser extends BaseUser {
  type: UserType.엔터프라이즈;
  businessType: UserBusinessType;
  businessManagerName: string;
  businessManagerPhone: string;
  admin: boolean;
}

export type User = NormalUser | EnterpriseUser;

export function isEnterpriseUser(user: User): user is EnterpriseUser {
  return user.type === UserType.엔터프라이즈;
}
